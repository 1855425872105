<template>
  <section>
    <Toast/>
    <div class="w-full mt-2 p-4 box">
      <div class="flex justify-between items-center">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-plus"></i>
          <p class="text-lg font-bold">Crear solicitud contrato</p>
        </div>
      </div>
      <div class="w-full">
        <div class="w-full">
          <label class="text-xs text-gray-600 italic" for="cliente">Contrato</label>
          <AutoComplete
            size="small"
            id="cliente"
            v-model="solicitudContratosStore.solicitud.ContratoId"
            dropdown class="w-full h-min"
            panelClass="text-xs"
            dropdownClass="h-10"
            inputClass="h-min"
            :suggestions="solicitudContratosStore._listado_contratos"
            @complete="buscarContratos($event)"
            optionLabel="Descript"
            :forceSelection="true"
            @item-select="solicitudContratosStore.solicitud.Articulos = []"
          >
            <template #option="slotProps">
              <div class="w-full flex items-center gap-2">
                <div>
                  <p>
                    {{ slotProps.option.CardCode + ' - ' + slotProps.option.Cliente.U_PHR_CardName }}
                  </p>
                  <div class="flex gap-2">
                    <span class="font-medium">Tipo de contrato</span>
                    {{ slotProps.option?.CtoMotAutoriza[0]?.MotAutoriza?.ModContrato?.descripcion }}
                  </div>
                  <div class="flex gap-2">
                    <span class="font-medium">Descripción</span>
                    <p>{{ slotProps.option.Descript }}</p>
                  </div>
                  <div class="flex gap-2">
                    <span class="font-medium">Motivos autorización</span>
                    <div v-for="(motivo, i) of slotProps.option.CtoMotAutoriza" :key="i">
                      {{ motivo?.MotAutoriza?.Nombre }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="w-full">
          <label class="text-xs text-gray-600 italic" for="cliente">Medicamento</label>
          <AutoComplete
            :disabled="!solicitudContratosStore.solicitud.ContratoId"
            size="small"
            id="cliente"
            v-model="articuloBuscado"
            @item-select="ingresarMedicamentos"
            dropdown class="w-full h-min"
            dropdownClass="h-10"
            inputClass="h-min"
            :suggestions="articulosBuscados"
            @complete="buscarArticulos($event)"
            :forceSelection="true"
          >
          <template #option="slotProps">
            <div class="flex gap-2 items-center">
              <img  style="left: -1.5rem" class="w-16 h-16 rounded-full shadow-lg" :src="`https://int.pharmasan.net/api/config-ov/articulos/get-image/${slotProps.option.PicturName}`">
              <div class="w-full text-xs">
                <p>
                  {{ slotProps.option.ItemCode + ' - ' + slotProps.option.ItemName }}
                </p>
                <div class="w-full flex gap-2 text-gray-600">
                  <p>Cum:</p>
                  {{ slotProps.option.SWW }}
                </div>
              </div>
            </div>
            </template>
          </AutoComplete>
        </div>
      </div>
      <div class="w-full mt-2">
        <DataTable :value="solicitudContratosStore._solicitud.Articulos" size="small" scrollable scrollHeight="60vh" class="text-sm" showGridlines tableStyle="min-width: 50rem">
          <Column field="ItemCode" header="Código medicamento"></Column>
          <Column field="ItemName" header="Nombre"></Column>
          <Column field="SWW" header="Cum"></Column>
          <Column header="Comentario">
            <template #body="slotProps">
              <Textarea  maxlength="255" rows="1" class="w-full" v-model="slotProps.data.comentario" />
            </template>
          </Column>
          <Column header="Soporte">
            <template #body="slotProps">
              <div class="flex gap-2">
                <div class="border p-2 bg-blue-300 rounded-md items-center flex gap-2 text-blue-600" v-if="slotProps.data.soporte">
                  {{ slotProps.data.soporte?.name }}
                  <button @click="slotProps.data.soporte = ''" class="rounded-"><i class="pi pi-times text-xs text-red-600 font-bold border rounded-full w-6 h-6 flex items-center justify-center border-red-400"></i></button>
                </div>
                <Button v-else icon="pi pi-paperclip" text @click="lineaArchivo = slotProps.data, displayDialog = true"></Button>
              </div>
            </template>
          </Column>
          <Column header="Acciones">
            <template #body="slotProps">
              <Button @click="eliminarLinea(slotProps.data.Id)" icon="pi pi-trash" severity="danger" rounded outlined aria-label="Cancel" />
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="w-full flex justify-end mt-3">
        <Button @click="crearSolicitud" label="Crear solicitud" class="w-56" icon="pi pi-plus" raised />
      </div>
    </div>
    <Dialog v-model:visible="displayDialog" modal header="Adjuntar soporte" :style="{ width: '25rem' }">
      <div class="border p-2 bg-blue-300 text-blue-600 rounded-md items-center flex" v-if="adjunto">
        {{ adjunto?.name }}
        <button @click="adjunto = ''" class="rounded-"><i class="pi pi-times text-xs text-red-600 font-bold border rounded-full w-6 h-6 flex items-center justify-center border-red-400"></i></button>
      </div>
      <FileUpload
        v-else
        mode="basic"
        name="demo[]"
        url="./upload.php"
        accept=".pdf"
        class="bg-white text-blue-600 w-full"
        chooseIcon="pi pi-paperclip"
        @change="subirArchivo($event)"
        ref="file"
      ></FileUpload>
      <div class="flex justify-between w-full gap-2 mt-4">
        <Button type="button" label="Cancelar" severity="secondary" @click="cerrarModal"></Button>
        <Button type="button" label="Guardar" @click="guardarArchivo"></Button>
      </div>
    </Dialog>
  </section>
</template>
<script>
  import { ref, onMounted } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { useSolicitudContratosStore } from '../../../stores/solicitud-contrato.store'
  import { useRouter } from 'vue-router'
  import { helper } from '@/utils/helper'
  import Swal from 'sweetalert2'
  export default {
    name: 'SolicitudesContratosCrear',
    setup () {
      const solicitudContratosStore = useSolicitudContratosStore()
      const toast = useToast()
      const router = useRouter()
      const file = ref()
      const adjunto = ref()
      const displayDialog = ref(false)
      const articulosBuscados = ref([])
      const lineaArchivo = ref()
      const articuloBuscado = ref()
      const buscarArticulos = (event) => {
        solicitudContratosStore.buscarArticulos({ name: event.query ? event.query : 'md0', contratoId: solicitudContratosStore._solicitud.ContratoId.Id }).then((data) => {
          if (solicitudContratosStore._solicitud.Articulos.length) {
            articulosBuscados.value = []
            for (const i of data) {
              if (!solicitudContratosStore._solicitud.Articulos.some(a => a.Id === i.Id)) {
                articulosBuscados.value.push({
                  ...i,
                  comentario: ''
                })
              }
            }
          } else {
            articulosBuscados.value = data.map(a => {
              return {
                ...a,
                comentario: ''
              }
            })
          }
        })
      }
      const buscarContratos = (event) => {
        solicitudContratosStore.buscarContratos({ name: event.query })
      }
      const ingresarMedicamentos = () => {
        solicitudContratosStore.solicitud.Articulos.splice(0, 0, {
          ...articuloBuscado.value,
          soporte: '',
          file: null
        })
        articuloBuscado.value = ''
      }
      const eliminarLinea = (id) => {
        solicitudContratosStore.solicitud.Articulos = solicitudContratosStore._solicitud.Articulos.filter(a => a.Id !== id)
      }
      const crearSolicitud = () => {
        if (!solicitudContratosStore._solicitud.Articulos) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes ingresar al menos un articulo para continuar', life: 3000 })
        if (!solicitudContratosStore._solicitud.ContratoId) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un contrato para continuar', life: 3000 })
        const payload = {
          contratoId: solicitudContratosStore._solicitud.ContratoId.Id,
          articulos: solicitudContratosStore._solicitud.Articulos.map(a => {
            return {
              Comentarios: a.comentario,
              ArticuloId: a.Id,
              soporte: a.soporte
            }
          })
        }
        solicitudContratosStore.crearSolicitud(payload).then((data) => {
          if (data) {
            Swal.fire({
              title: 'Guardado',
              text: 'Solicitud creada con exito',
              icon: 'success'
            }).then(() => {
              router.push({ name: 'pharmasan.ventas.contrato-precio.solicitudes.listado' })
              solicitudContratosStore.solicitud = {
                ContratoId: 0,
                Articulos: []
              }
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al crear la solicitud, intenta nuevamente'
            })
          }
        })
      }
      const subirArchivo = async (event) => {
        const archivo = file.value.files[0]
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            const base64 = await helper.base64String(archivo)
            adjunto.value = { base64, name: archivo.name }
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const guardarArchivo = () => {
        solicitudContratosStore.solicitud.Articulos.map(async a => {
          if (a.Id === lineaArchivo.value.Id) {
            a.soporte = adjunto.value
          }
        })
        cerrarModal()
      }
      const cerrarModal = () => {
        displayDialog.value = false
        lineaArchivo.value = null
        adjunto.value = null
      }
      onMounted(() => {
        solicitudContratosStore.obtenerMotivosAutorizacion()
      })
      return {
        solicitudContratosStore,
        buscarArticulos,
        articuloBuscado,
        buscarContratos,
        ingresarMedicamentos,
        eliminarLinea,
        articulosBuscados,
        crearSolicitud,
        file,
        subirArchivo,
        adjunto,
        displayDialog,
        lineaArchivo,
        guardarArchivo,
        cerrarModal
      }
    }
  }
</script>
